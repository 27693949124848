.profile{
    width: 40%; 
    margin: 5% auto;
    padding: 2% 0; 

    input{
        margin: 3% auto; 

    }
    button{
        margin: 1%;
    }
}