.tableData{
    width: 86%;
    margin: 1% auto; 
    padding: 0.5%; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // border: 2px solid #E84C3D; 
    

    h1{
        margin-top: 0.1%; 
        margin-bottom: 1%; 
        padding: 0.25%; 
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background-color: #E84C3D; 
        color: white; 
        text-align: center;
    }
}