body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form.auth-form{max-width:300px;margin:auto;margin-top:5rem}form.auth-form>*{display:block;width:100%}form.auth-form button{margin:2% auto;padding:6% 0;width:75%;font-size:24px;background-color:#E84C3D;border-color:white;color:white;border-radius:10px}form.auth-form button:hover{background-color:#F0897F;border-color:#81180E;color:black;transition-duration:1000ms}form.auth-form button.active{border:1px solid #fff}form.auth-form span.error{display:block;color:red}.login-container{padding:8% 40%}.login-container #components-form-demo-normal-login .login-form{max-width:300px}.login-container #components-form-demo-normal-login .login-form-forgot{float:right}.login-container #components-form-demo-normal-login .ant-col-rtl .login-form-forgot{float:left}.login-container #components-form-demo-normal-login .login-form-button{width:100%}

form.register-form{max-width:300px;margin:auto;margin-top:5rem}form.register-form>*{display:block;width:100%}form.register-form span.error{display:block;color:red}form.register-form button{background-color:#FF3F18}

.product{display:flex;flex-wrap:wrap;flex-direction:row;justify-content:center;margin:auto;padding:1%;max-width:100%;border:1px solid black}.product form{margin:auto;width:50%}.chart{margin:auto;width:60%}.filter{margin:auto;padding:2%;display:flex;flex-wrap:wrap;justify-content:center}.filter label{margin:auto;padding:1%;color:blue}.filter select{width:100%;margin:1%}.product-summary{width:100%;display:flex;flex-wrap:wrap;flex-direction:row;justify-content:flex-end;align-items:flex-start;border:1px solid black}.product-summary h1{background-color:#EAF2D7;width:100%;padding:1% 0;text-align:center}.product-summary span{margin:1%;padding:1%;width:30%;text-align:right}.product-summary h2{display:inline;max-width:100%;margin:1%;padding:1%}.data-quality{width:40%;display:flex;flex-wrap:wrap;flex-direction:row;justify-content:flex-end;align-items:flex-start;border:1px solid black}.data-quality span{width:25%;text-align:right;padding:1%;margin:1%}.data-quality h1{width:100%;padding:1% 0;text-align:center;background-color:#EAF2D7}.data-quality h2{display:inline;margin:1%;padding:1%}.product-search{width:30%;display:flex;flex-wrap:wrap;flex-direction:row;justify-content:flex-end;align-items:flex-start;border:1px solid black}.product-search span{width:25%;text-align:right;padding:1%;margin:1%}.product-search h1{width:100%;padding:1% 0;text-align:center;background-color:#EAF2D7}.product-search h2{margin:1%;padding:1%}.linechart{width:86%;margin:1%;padding:1%;background-color:white;border:0.5px solid gray;box-shadow:0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);display:flex;align-items:center;justify-content:center}

@keyframes dtb-spinner {
    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes dtb-spinner {
    100% {
        transform: rotate(360deg)
    }
}

div.dt-button-info {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    margin-top: -100px;
    margin-left: -200px;
    background-color: white;
    border: 2px solid #111;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    text-align: center;
    z-index: 21
}

div.dt-button-info h2 {
    padding: 0.5em;
    margin: 0;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    background-color: #f3f3f3
}

div.dt-button-info>div {
    padding: 1em
}

div.dt-button-collection-title {
    text-align: center;
    padding: 0.3em 0 0.5em;
    font-size: 0.9em
}

div.dt-button-collection-title:empty {
    display: none
}

button.dt-button, div.dt-button, a.dt-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin-right: 0.333em;
    margin-bottom: 0.333em;
    padding: 0.5em 1em;
    border: 1px solid #999;
    border-radius: 2px;
    cursor: pointer;
    font-size: 0.88em;
    line-height: 1.6em;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    background-color: #e9e9e9;
    background-image: linear-gradient(to bottom, #fff 0%, #e9e9e9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='white', EndColorStr='#e9e9e9');
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    outline: none;
    text-overflow: ellipsis
}

button.dt-button.disabled, div.dt-button.disabled, a.dt-button.disabled {
    color: #999;
    border: 1px solid #d0d0d0;
    cursor: default;
    background-color: #f9f9f9;
    background-image: linear-gradient(to bottom, #fff 0%, #f9f9f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='#ffffff', EndColorStr='#f9f9f9')
}

button.dt-button:active:not(.disabled), button.dt-button.active:not(.disabled), div.dt-button:active:not(.disabled), div.dt-button.active:not(.disabled), a.dt-button:active:not(.disabled), a.dt-button.active:not(.disabled) {
    background-color: #e2e2e2;
    background-image: linear-gradient(to bottom, #f3f3f3 0%, #e2e2e2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='#f3f3f3', EndColorStr='#e2e2e2');
    box-shadow: inset 1px 1px 3px #999999
}

button.dt-button:active:not(.disabled):hover:not(.disabled), button.dt-button.active:not(.disabled):hover:not(.disabled), div.dt-button:active:not(.disabled):hover:not(.disabled), div.dt-button.active:not(.disabled):hover:not(.disabled), a.dt-button:active:not(.disabled):hover:not(.disabled), a.dt-button.active:not(.disabled):hover:not(.disabled) {
    box-shadow: inset 1px 1px 3px #999999;
    background-color: #cccccc;
    background-image: linear-gradient(to bottom, #eaeaea 0%, #ccc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='#eaeaea', EndColorStr='#cccccc')
}

button.dt-button:hover, div.dt-button:hover, a.dt-button:hover {
    text-decoration: none
}

button.dt-button:hover:not(.disabled), div.dt-button:hover:not(.disabled), a.dt-button:hover:not(.disabled) {
    border: 1px solid #666;
    background-color: #e0e0e0;
    background-image: linear-gradient(to bottom, #f9f9f9 0%, #e0e0e0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='#f9f9f9', EndColorStr='#e0e0e0')
}

button.dt-button:focus:not(.disabled), div.dt-button:focus:not(.disabled), a.dt-button:focus:not(.disabled) {
    border: 1px solid #426c9e;
    text-shadow: 0 1px 0 #c4def1;
    outline: none;
    background-color: #79ace9;
    background-image: linear-gradient(to bottom, #bddef4 0%, #79ace9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='#bddef4', EndColorStr='#79ace9')
}

.dt-button embed {
    outline: none
}

div.dt-buttons {
    position: relative;
    float: left
}

div.dt-buttons.buttons-right {
    float: right
}

div.dataTables_layout_cell div.dt-buttons {
    float: none
}

div.dataTables_layout_cell div.dt-buttons.buttons-right {
    float: none
}

div.dt-button-collection {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    margin-top: 3px;
    padding: 8px 8px 4px 8px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: white;
    overflow: hidden;
    z-index: 2002;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    box-sizing: border-box
}

div.dt-button-collection button.dt-button, div.dt-button-collection div.dt-button, div.dt-button-collection a.dt-button {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    display: block;
    float: none;
    margin-bottom: 4px;
    margin-right: 0
}

div.dt-button-collection button.dt-button:active:not(.disabled), div.dt-button-collection button.dt-button.active:not(.disabled), div.dt-button-collection div.dt-button:active:not(.disabled), div.dt-button-collection div.dt-button.active:not(.disabled), div.dt-button-collection a.dt-button:active:not(.disabled), div.dt-button-collection a.dt-button.active:not(.disabled) {
    background-color: #dadada;
    background-image: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, StartColorStr='#f0f0f0', EndColorStr='#dadada');
    box-shadow: inset 1px 1px 3px #666
}

div.dt-button-collection.fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -75px;
    border-radius: 0
}

div.dt-button-collection.fixed.two-column {
    margin-left: -200px
}

div.dt-button-collection.fixed.three-column {
    margin-left: -225px
}

div.dt-button-collection.fixed.four-column {
    margin-left: -300px
}

div.dt-button-collection>:last-child {
    display: block !important;
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    -ms-column-gap: 8px;
    -o-column-gap: 8px;
    grid-column-gap: 8px;
    column-gap: 8px
}

div.dt-button-collection>:last-child>* {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid
}

div.dt-button-collection.two-column {
    width: 400px
}

div.dt-button-collection.two-column>:last-child {
    padding-bottom: 1px;
    -webkit-column-count: 2;
    -ms-column-count: 2;
    -o-column-count: 2;
    column-count: 2
}

div.dt-button-collection.three-column {
    width: 450px
}

div.dt-button-collection.three-column>:last-child {
    padding-bottom: 1px;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3
}

div.dt-button-collection.four-column {
    width: 600px
}

div.dt-button-collection.four-column>:last-child {
    padding-bottom: 1px;
    -webkit-column-count: 4;
    -ms-column-count: 4;
    -o-column-count: 4;
    column-count: 4
}

div.dt-button-collection .dt-button {
    border-radius: 0
}

div.dt-button-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
    z-index: 2001
}

@media screen and (max-width: 640px) {
    div.dt-buttons {
        float: none !important;
        text-align: center
    }
}

button.dt-button.processing, div.dt-button.processing, a.dt-button.processing {
    color: rgba(0, 0, 0, 0.2)
}

button.dt-button.processing:after, div.dt-button.processing:after, a.dt-button.processing:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin: -8px 0 0 -8px;
    box-sizing: border-box;
    display: block;
    content: ' ';
    border: 2px solid #282828;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: dtb-spinner 1500ms infinite linear;
    -o-animation: dtb-spinner 1500ms infinite linear;
    -ms-animation: dtb-spinner 1500ms infinite linear;
    -webkit-animation: dtb-spinner 1500ms infinite linear;
    -moz-animation: dtb-spinner 1500ms infinite linear
}
.tableData{width:86%;margin:1% auto;padding:0.5%;box-shadow:0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)}.tableData h1{margin-top:0.1%;margin-bottom:1%;padding:0.25%;box-shadow:0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);background-color:#E84C3D;color:white;text-align:center}

/*
 * Table styles
 */
 table.dataTable {

    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
    /*
     * Header and footer styles
     */
    /*
     * Body styles
     */
  }
  table.dataTable thead th,
  table.dataTable tfoot th {
    font-weight: bold;
  }
  table.dataTable thead th,
  table.dataTable thead td {
    padding: 10px 18px;
    border-bottom: 1px solid #111111;
  }
  table.dataTable thead th:active,
  table.dataTable thead td:active {
    outline: none;
  }
  table.dataTable tfoot th,
  table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #111111;
  }
  table.dataTable thead .sorting,
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc {
    cursor: pointer;
    *cursor: hand;
  }
  table.dataTable thead .sorting,
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc,
  table.dataTable thead .sorting_asc_disabled,
  table.dataTable thead .sorting_desc_disabled {
    background-repeat: no-repeat;
    background-position: center right;
  }
  
  table.dataTable tbody tr {
    background-color: white;
  }
  table.dataTable tbody tr.selected {
    background-color: #b0bed9;
  }
  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 8px 10px;
  }
  table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
    border-top: 1px solid #dddddd;
  }
  table.dataTable.row-border tbody tr:first-child th,
  table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th,
  table.dataTable.display tbody tr:first-child td {
    border-top: none;
  }
  table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }
  table.dataTable.cell-border tbody tr th:first-child,
  table.dataTable.cell-border tbody tr td:first-child {
    border-left: 1px solid #dddddd;
  }
  table.dataTable.cell-border tbody tr:first-child th,
  table.dataTable.cell-border tbody tr:first-child td {
    border-top: none;
  }
  table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: #f9f9f9;
  }
  table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
    background-color: #abb9d3;
  }
  table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
    background-color: whitesmoke;
  }
  table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected {
    background-color: #a9b7d1;
  }
  table.dataTable.order-column tbody tr > .sorting_1,
  table.dataTable.order-column tbody tr > .sorting_2,
  table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1,
  table.dataTable.display tbody tr > .sorting_2,
  table.dataTable.display tbody tr > .sorting_3 {
    background-color: #f9f9f9;
  }
  table.dataTable.order-column tbody tr.selected > .sorting_1,
  table.dataTable.order-column tbody tr.selected > .sorting_2,
  table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1,
  table.dataTable.display tbody tr.selected > .sorting_2,
  table.dataTable.display tbody tr.selected > .sorting_3 {
    background-color: #acbad4;
  }
  table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #f1f1f1;
  }
  table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #f3f3f3;
  }
  table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke;
  }
  table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #a6b3cd;
  }
  table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #a7b5ce;
  }
  table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #a9b6d0;
  }
  table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #f9f9f9;
  }
  table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #fbfbfb;
  }
  table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #fdfdfd;
  }
  table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #acbad4;
  }
  table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #adbbd6;
  }
  table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #afbdd8;
  }
  table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
    background-color: #eaeaea;
  }
  table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
    background-color: #ebebeb;
  }
  table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
    background-color: #eeeeee;
  }
  table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
    background-color: #a1aec7;
  }
  table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
    background-color: #a2afc8;
  }
  table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
    background-color: #a4b2cb;
  }
  table.dataTable.no-footer {
    border-bottom: 1px solid #111111;
  }
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap;
  }
  table.dataTable.compact thead th,
  table.dataTable.compact thead td {
    padding: 4px 17px 4px 4px;
  }
  table.dataTable.compact tfoot th,
  table.dataTable.compact tfoot td {
    padding: 4px;
  }
  table.dataTable.compact tbody th,
  table.dataTable.compact tbody td {
    padding: 4px;
  }
  table.dataTable th.dt-left,
  table.dataTable td.dt-left {
    text-align: left;
  }
  table.dataTable th.dt-center,
  table.dataTable td.dt-center,
  table.dataTable td.dataTables_empty {
    text-align: center;
  }
  table.dataTable th.dt-right,
  table.dataTable td.dt-right {
    text-align: right;
  }
  table.dataTable th.dt-justify,
  table.dataTable td.dt-justify {
    text-align: justify;
  }
  table.dataTable th.dt-nowrap,
  table.dataTable td.dt-nowrap {
    white-space: nowrap;
  }
  table.dataTable thead th.dt-head-left,
  table.dataTable thead td.dt-head-left,
  table.dataTable tfoot th.dt-head-left,
  table.dataTable tfoot td.dt-head-left {
    text-align: left;
  }
  table.dataTable thead th.dt-head-center,
  table.dataTable thead td.dt-head-center,
  table.dataTable tfoot th.dt-head-center,
  table.dataTable tfoot td.dt-head-center {
    text-align: center;
  }
  table.dataTable thead th.dt-head-right,
  table.dataTable thead td.dt-head-right,
  table.dataTable tfoot th.dt-head-right,
  table.dataTable tfoot td.dt-head-right {
    text-align: right;
  }
  table.dataTable thead th.dt-head-justify,
  table.dataTable thead td.dt-head-justify,
  table.dataTable tfoot th.dt-head-justify,
  table.dataTable tfoot td.dt-head-justify {
    text-align: justify;
  }
  table.dataTable thead th.dt-head-nowrap,
  table.dataTable thead td.dt-head-nowrap,
  table.dataTable tfoot th.dt-head-nowrap,
  table.dataTable tfoot td.dt-head-nowrap {
    white-space: nowrap;
  }
  table.dataTable tbody th.dt-body-left,
  table.dataTable tbody td.dt-body-left {
    text-align: left;
  }
  table.dataTable tbody th.dt-body-center,
  table.dataTable tbody td.dt-body-center {
    text-align: center;
  }
  table.dataTable tbody th.dt-body-right,
  table.dataTable tbody td.dt-body-right {
    text-align: right;
  }
  table.dataTable tbody th.dt-body-justify,
  table.dataTable tbody td.dt-body-justify {
    text-align: justify;
  }
  table.dataTable tbody th.dt-body-nowrap,
  table.dataTable tbody td.dt-body-nowrap {
    white-space: nowrap;
  }
  
  table.dataTable,
  table.dataTable th,
  table.dataTable td {
    box-sizing: content-box;
  }
  
  /*
   * Control feature layout
   */
  .dataTables_wrapper {
    position: relative;
    clear: both;
    *zoom: 1;
    zoom: 1;
  }
  .dataTables_wrapper .dataTables_length {
    float: left;
  }
  .dataTables_wrapper .dataTables_filter {
    float: right;
    text-align: right;
  }
  .dataTables_wrapper .dataTables_filter input {
    margin-left: 0.5em;
  }
  .dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
  }
  .dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    *cursor: hand;
    color: #333333 !important;
    border: 1px solid transparent;
    border-radius: 2px;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #333333 !important;
    border: 1px solid #979797;
    background-color: white;
    /* Chrome,Safari4+ */
    /* Chrome10+,Safari5.1+ */
    /* FF3.6+ */
    /* IE10+ */
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, white 0%, gainsboro 100%);
    /* W3C */
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #666 !important;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    border: 1px solid #111111;
    background-color: #585858;
    /* Chrome,Safari4+ */
    /* Chrome10+,Safari5.1+ */
    /* FF3.6+ */
    /* IE10+ */
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, #585858 0%, #111111 100%);
    /* W3C */
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    outline: none;
    background-color: #2b2b2b;
    /* Chrome,Safari4+ */
    /* Chrome10+,Safari5.1+ */
    /* FF3.6+ */
    /* IE10+ */
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
    /* W3C */
    box-shadow: inset 0 0 3px #111;
  }
  .dataTables_wrapper .dataTables_paginate .ellipsis {
    padding: 0 1em;
  }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  }
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    color: #333333;
  }
  .dataTables_wrapper .dataTables_scroll {
    clear: both;
  }
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    *margin-top: -1px;
    -webkit-overflow-scrolling: touch;
  }
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td {
    vertical-align: middle;
  }
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th > div.dataTables_sizing,
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td > div.dataTables_sizing {
    height: 0;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
  }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #111111;
  }
  .dataTables_wrapper.no-footer div.dataTables_scrollHead table,
  .dataTables_wrapper.no-footer div.dataTables_scrollBody table {
    border-bottom: none;
  }
  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
  
  @media screen and (max-width: 767px) {
    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_paginate {
      float: none;
      text-align: center;
    }
    .dataTables_wrapper .dataTables_paginate {
      margin-top: 0.5em;
    }
  }
  @media screen and (max-width: 640px) {
    .dataTables_wrapper .dataTables_length,
    .dataTables_wrapper .dataTables_filter {
      float: none;
      text-align: center;
    }
    .dataTables_wrapper .dataTables_filter {
      margin-top: 0.5em;
    }
  }
.profile{width:40%;margin:5% auto;padding:2% 0}.profile input{margin:3% auto}.profile button{margin:1%}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

