form.auth-form {
  max-width: 300px;
  margin: auto;
  margin-top: 5rem;
  > * {
    display: block;
    width: 100%;
  }

  button{
    margin: 2% auto; 
    padding: 6% 0; 
    width: 75%; 
    font-size: 24px; 
    background-color: #E84C3D;
    border-color: white; 
    color: white; 
    border-radius: 10px; 
    &:hover{
      background-color: #F0897F;
      border-color: #81180E;
      color: black; 
      transition-duration: 1000ms;
    }
    &.active {
      border: 1px solid #fff;
    }
  }



  span.error {
    display: block;
    color: red;
  }
}

// Specialized Login form styling from Ant Design
.login-container{
  
  padding: 8% 40%; 

  #components-form-demo-normal-login .login-form {
    max-width: 300px;
  }
  #components-form-demo-normal-login .login-form-forgot {
    float: right;
  }
  #components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
  }
  #components-form-demo-normal-login .login-form-button {
    width: 100%;
  }
}

////