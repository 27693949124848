.product{
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row;
    justify-content: center;

    margin: auto; 
    padding: 1%; 
    max-width: 100%; 
    border: 1px solid black; 

    form{
        margin: auto; 
        width: 50%; 
    }
}

.chart{
    margin: auto; 
    width: 60%; 
}

.filter{
    margin: auto; 
    padding: 2%; 
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;

    label{
        margin: auto; 
        padding: 1%; 
        color: blue; 
    }

    select{
        width: 100%; 
        margin: 1%; 
    }
}

.product-summary{

    width: 100%; 
    display: flex; 
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    border: 1px solid black; 

    h1{
        background-color: #EAF2D7;
        width: 100%; 
        padding: 1% 0;  
        text-align: center;
    }

    span{
        margin: 1%; 
        padding: 1%; 
        width: 30%; 
        text-align: right;
    }

    h2{
        display: inline; 
        max-width: 100%; 
        margin: 1%; 
        padding: 1%;        
    }
}

.data-quality{
    width: 40%; 
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    border: 1px solid black; 

    span{
        width: 25%; 
        text-align: right;
        padding: 1%; 
        margin: 1%; 
    }

    h1{
        width: 100%;
        padding: 1% 0;  
        text-align: center;
        background-color: #EAF2D7;
    } 
    
    h2{
        display: inline; 
        margin: 1%; 
        padding: 1%; 
    }
}

.product-search{
    width: 30%; 
    display: flex; 
    flex-wrap: wrap; 
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    border: 1px solid black; 

    span{
        width: 25%; 
        text-align: right;
        padding: 1%; 
        margin: 1%; 
    }

    h1{
        width: 100%;
        padding: 1% 0;  
        text-align: center;
        background-color: #EAF2D7;
    } 
    
    h2{
        margin: 1%; 
        padding: 1%; 
    }
}

.linechart{
    width: 86%; 
    margin: 1%; 
    padding: 1%; 
    background-color: white;
    border: 0.5px solid gray; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex; 
    align-items: center;
    justify-content: center;
}

